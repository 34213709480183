import React from "react";
import { Badge, Box, IconButton } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import { useCart } from "./cart";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

type MenubarProps = {
  logo?: IGatsbyImageData;
  root: string;
  url: string;
};

export const Menubar = ({ logo, url, root }: MenubarProps) => {
  const { items } = useCart()!;
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: "9999",
        width: "100%",
        top: "0",
        height: "4rem",
        backgroundColor: "background.paper",
        padding: "0.625rem",
        boxShadow: "0 0.25rem 0.25rem -0.25rem rgba(0,0,0,0.25)",
        display: "flex",
        justifyContent: {
          xs: "space-between",
          md: "center",
        },
      }}
    >
      <IconButton
        href={url}
        rel="noopener"
        target="_blank"
        sx={{
          display: "inline-block",
          width: "2.75rem",
          height: "2.75rem",
          p: 0,
        }}
      >
        {logo && <GatsbyImage alt="logo" image={logo} />}
      </IconButton>
      <IconButton
        sx={{
          display: {
            xs: "inline-block",
            md: "none",
          },
        }}
        href={`${root}/#cart`}
      >
        <Badge
          sx={{
            ".MuiBadge-badge": {
              borderColor: "background.paper",
              borderStyle: "solid",
              borderWidth: "0.125rem",
              padding: "0 0.25rem",
            },
          }}
          badgeContent={items.reduce(
            (total, { quantity }) => total + quantity,
            0
          )}
          color="primary"
        >
          <ShoppingCart color="secondary" />
        </Badge>
      </IconButton>
    </Box>
  );
};
