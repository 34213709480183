import React from "react";
import { Menubar } from "./menubar";
import { Banner } from "../molecules/banner";
import { GatsbyShop } from "../utils/shop";

type HeaderProps = {
  shop: GatsbyShop;
  root: string;
};

export const Header = ({
  root,
  shop: { logo, url, banner, name, baseline },
}: HeaderProps) => {
  return (
    <>
      <Menubar
        logo={logo?.childImageSharp?.gatsbyImageData}
        url={url}
        root={root}
      />
      <Banner banner={banner} name={name} baseline={baseline} />
    </>
  );
};
