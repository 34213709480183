import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { Footer } from "../organisms/footer";
import { Header } from "../organisms/header";
import { CartProvider } from "../organisms/cart";
import { CookieBanner } from "../organisms/cookie-banner";
import { Reassurances } from "../molecules/reassurances";
import { GatsbyShop } from "../utils/shop";

export const Layout = ({
  children,
  shop,
  root,
}: PropsWithChildren<{ shop: GatsbyShop; root: string }>) => {
  return (
    <CartProvider shop={shop}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header root={root} shop={shop} />
        <Reassurances shop={shop} />
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
        <Footer {...shop} root={root} />
        <CookieBanner />
      </Box>
    </CartProvider>
  );
};
